<template>
  <v-btn
    elevation="1"
    fab
    tile
    small
    class="ml-3 mr-4 toggle-btn"
    :style="{ background: $vuetify.theme.themes[theme].toggleBG }"
    @click="mini = !mini"
  >
    <v-icon>
      {{ mini ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}
    </v-icon>
  </v-btn>
</template>

<script>
// Utilities
import { sync } from 'vuex-pathify'

export default {
  name: 'DefaultDrawerToggle',

  computed: {
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    mini: sync('app/mini')
  }
}
</script>
